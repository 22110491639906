/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/owl.carousel@2.3.4/dist/owl.carousel.min.js
 * - /npm/basicscroll@3.0.4/dist/basicScroll.min.js
 * - /npm/js-cookie@2.2.1/src/js.cookie.min.js
 * - /npm/plyr@3.7.8/dist/plyr.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
